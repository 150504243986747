<script>
import Parcel from '../../services/parcel';

export default {
  props: {
    id: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      number: ''
    };
  },
  methods: {
    getParcelNumber() {
      const parcel = new Parcel({ id: this.id });
      parcel
        .getNumber()
        .then(number => {
          this.number = number;
        })
        .catch(() => {});
    }
  },
  watch: {
    id: {
      handler() {
        this.getParcelNumber();
      },
      immediate: true
    }
  },

  render(h) {
    return h('div', {}, this.number);
  }
};
</script>
